import React from 'react';
import { Helmet } from 'react-helmet';
import './css/Expertise.css';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import TextBlock from '../components/textblock';
import profilePic from '../assets/profilePic2.jpeg';
import Animator from '../components/Animator';
import Recommendations from '../components/RecommendationsList';
import useScrollToAnchor from '../components/useScrollToAnchor';

function Expertise() {

    useScrollToAnchor();

    return (
        <div className="Expertise">
            <h1>My Expertise</h1>
            <FetchAndRenderMarkdown 
                url="/projects/Expertise/section1.md" 
                wrapperClassName={"Section1-Wrapper"}
                WrapperComponent={Animator} 
                Component={TextBlock} 
                containerClassName="Animated-Container"
                id = "section1"
            />
            <FetchAndRenderMarkdown 
                url="/projects/Expertise/section2.md" 
                wrapperClassName={"Row Section2-Wrapper"}
                WrapperComponent={Animator} 
                Component={TextBlock} 
                containerClassName="Animated-Container"
                breakBySection
                id = "section2"
            />
            <FetchAndRenderMarkdown 
                url="/projects/Expertise/section3.md" 
                wrapperClassName={"Row Section3-Wrapper"}
                WrapperComponent={Animator} 
                Component={TextBlock} 
                containerClassName="Animated-Container"
                breakBySection
                id = "section3"
            />
            <FetchAndRenderMarkdown 
                url="/projects/Expertise/section4.md" 
                WrapperComponent={Animator} 
                Component={TextBlock} 
                containerClassName="Animated-Container"
                id = "section4"
            />
            <div id='recommendations' className='Recommendations'>
                <h1>Testimonials</h1>
                <Recommendations
                    Component={Animator}
                    className="Recommendations-Wrapper"
                    containerClassName="Animated-Container"
                />
            </div>
        </div>
    );
}

export default Expertise;