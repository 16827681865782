import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/Portfolio.css';
import TextBlock from '../../components/textblock';
import FetchAndRenderMarkdown from '../../components/FetchAndRenderMarkdown';
import useScrollToAnchor from '../../components/useScrollToAnchor';
import Animator from '../../components/Animator';

function Portfolio() {

    useScrollToAnchor();

    return (
        <div className="Portfolio">
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container"
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Header.md' 
                Component={TextBlock} 
                id='intro'
            />
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container"                 
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Section1.md' 
                Component={TextBlock} 
                id='principlesInDevelopment'
            />
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Section2.md' 
                Component={TextBlock} 
                id='aesthetics'
            />
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Section2-5.md' 
                Component={TextBlock} 
                id='seo'
            />
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Section3.md' 
                Component={TextBlock} 
                id='conclusion'
            />
        </div>
    );
}

export default Portfolio;