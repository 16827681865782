import React, { useEffect, useRef } from 'react';
import './css/animator.css';

function Animator({ children, className }) {
    const observerRef = useRef();

    useEffect(() => {
        observerRef.current = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('Visible');
                } else {
                    entry.target.classList.remove('Visible');
                }
            });
        }, { threshold: [0.1, 0.25, 0.5, 0.75, 1] });

        const observer = observerRef.current;

        const containers = document.querySelectorAll('.Animated-Container');
        
        containers.forEach(container => {
            observer.observe(container);
        });

        // Clean up
        return () => {
            if (observer) {
                containers.forEach(container => {
                    observer.unobserve(container);
                });
            }
        };
    }, []);

    return (
        <div className={`AnimatedSection ${className}`}>
            {React.Children.map(children, (child, index) => {
                return React.cloneElement(child, { className: `${child.props.className} Animated-Container` });
            })}
        </div>
    );
}

export default Animator;