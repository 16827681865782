import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/FinancialAnalysisApp.css';
import Section from '../../components/section';
import TextBlock from '../../components/textblock';
import FetchAndRenderMarkdown from '../../components/FetchAndRenderMarkdown';
import useScrollToAnchor from '../../components/useScrollToAnchor';
import Animator from '../../components/Animator';
import Section2 from '../../components/section2';

function FAA() {

    useScrollToAnchor();

    return (
        <div className="FAA">
            <FetchAndRenderMarkdown 
                className="Header"
                url='/projects/FinancialAnalysisApp/Header.md' 
                Component={TextBlock} 
                id='intro'
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
            />
            <FetchAndRenderMarkdown 
                url='/projects/FinancialAnalysisApp/Section1.md'
                images={[{ images: ['/projects/FinancialAnalysisApp/API.png'], imagePosition: 'left'}]}
                Component={Section2} 
                id='api-integration'
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
            />
            <FetchAndRenderMarkdown 
                url='/projects/FinancialAnalysisApp/Section2.md'
                id='data-management'
                WrapperComponent={Animator}
                containerClassName="Animated-Container"
                images={[{ images: ['/projects/FinancialAnalysisApp/DataManagement.png'], imagePosition: 'right'}]}
                Component={Section2} 
            />
            <FetchAndRenderMarkdown 
                url='/projects/FinancialAnalysisApp/Section3.md'
                id='data-rendering'
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                images={[{ images: ['/projects/FinancialAnalysisApp/DataRendering.png'], imagePosition: 'left'}]}
                Component={Section2} 
            />
            <FetchAndRenderMarkdown 
                url='/projects/FinancialAnalysisApp/Section4.md'
                id='testing'
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                images={[{ images: ['/projects/FinancialAnalysisApp/Tests.png'], imagePosition: 'right'}]}
                Component={Section2} 
            />
            <FetchAndRenderMarkdown 
                className="Header"
                url='/projects/FinancialAnalysisApp/Section5.md' 
                Component={TextBlock} 
                id='docker'
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
            />
        </div>
    );
}

export default FAA;