import FBPweb from './HiddenPages/FlourishBrandPhotography';
import FAA from './HiddenPages/FinancialAnalysisApp';
import Porfolio from './HiddenPages/Portfolio';
// import other pages here

export default [
  {
    path: '/Projects/Development/FlourishBrandPhotography',
    component: FBPweb,
    name: 'FBPweb'
  },
  {
    path: '/Projects/FAA',
    component: FAA,
    name: 'FAA'
  },
  {
    path: '/Projects/Portfolio',
    component: Porfolio,
    name: 'Porfolio'
  },
  // add other pages here
];