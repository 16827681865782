import React from 'react';
import { Helmet } from 'react-helmet';
import './css/Resume.css';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import TextBlock from '../components/textblock';
import downloadButton from '../assets/downloadButton.png';
import resumePDF from '../assets/Resume.pdf';
import Animator from '../components/Animator';

function Resume() {
    return (
        <div className="Resume">
            <div className="download-section">
                <a href={resumePDF} download="Resume.pdf">
                    <img src={downloadButton} alt="Download button" className="download-button" />
                </a>
                <p className="download-text">Download Resume</p>
            </div>
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container"
                url="/Resume.md" 
                Component={TextBlock} 
                wrapperClassName="Text-Wrapper" 
                componentClassName="Text-Section" 
                breakBySection 
            />
        </div>
    );
}

export default Resume;