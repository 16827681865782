import React from 'react';
import { Helmet } from 'react-helmet';
import './css/Home.css';
import Headshot from '../components/headshot';
import myHeadshot from '../assets/headshot.jpeg';
import NameTitle from '../components/nametitle';
import TextBlock from '../components/textblock';
import pages from './pagesConfig';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className="Home">
            <div className='headshot'>
                <Headshot className='headshot-container' imageUrl={myHeadshot} />
                <NameTitle />
                <div className='navigation'>
                    {pages.filter(page => page.path !== '/').map((page, index) => (
                        <Link key={index} to={page.path}>
                            <TextBlock text={page.name}></TextBlock>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Home;